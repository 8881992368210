export const MESSAGE_EN = {
    tablasTipo: {
        tipoDocumento: {
            nit: 'Nit',
            cedulaCiudadania: 'Citizenship card',
            CedulaExtranjeria: 'Foreigner ID',
            telefonoMovil: 'Cell phone number'
        },
        tipoGenero: {
            masculino: 'Male',
            femenino: 'Feminine'
        },
        tipoPersona: {
            vendedor: 'Seller',
            lead: 'Lead',
            empleado: 'Employee',
            proveedor: 'Supplier',
            medico: 'Medical',
            paciente: 'Patient'
        }
    },

    paginator: {
        anterior: 'Prev',
        siguiente: 'Next'
    },

    general:{
        guardarExitoso: 'Registration saved correctly',
        eliminarExitoso: 'Registry deleted successfully',
        guardarFallido: 'Failed to save information. ',
        errorExisteRegistro: 'Information already exists.',
        dashboard: 'Dashboard',
        acciones: 'Actions',
        consultar: 'Consult',
        nuevo: 'New',
        eliminar: 'Delete',
        guardar: 'Save',
        filtros: 'Filters',
        seleccionFiltros: 'Select your filters',
        aplicar: 'Apply',
        cerrar: 'Close',
        volver: 'Volver',
        select_todos: 'All',
        sinGrupo: 'No group',
        eliminarConfirm: 'Do you really want to delete this record?',
        si: 'Yes',
        no: 'Not',
        accionesVer: 'View',
        accionesEditar: 'Edit',
        accionesEliminar: 'Delete',
        accionesDescargar: 'Download',
        grupoDeInformacion: 'Information group',
        grupoDeInformacionGlobal: 'Global Information Group',
        startdate: 'Start date',
        enddate: 'End date',
        mensaje: 'Message',
        errorInesperado: 'An unexpected error occurred, please contact the platform administrator',
        notificacion: 'Notification of incoming messages',
        limpiarFiltros: 'Clean filters',
        limpiar: 'Clean',
        fechaCreacion: 'Creation date',
        excel: 'Excel',
        errorConsultaFiltros: 'An error occurred consulting the information',
        refrescar: 'Refresh',
        global: 'Global',
        gestionDocumental: 'Document management',
        fecha: 'Date',
        retornar: 'Return',
        enviar:'Send',
        agregar: 'Add'
    },

    home: {
        bienvenidos: 'Already have an account? Login!'
    },

    login: {
        ingresar: 'Enter',
        mensaje_ingresar:'Welcome to ',
        userName: 'Usuario',
        placeHolderUserName: 'Enter the username',
        contrasena: 'Password',
        placeHolderContrasenia: 'Enter the password',
        loginFail: 'Access denied',
        loginCamposVacios: 'Must fill in first username and password',
        recordarContrasena: 'Remember password',
        recordarSucces: 'An Email was sent with the new password',
        celular: 'Cell phone',
        placeHolderCelular: 'Enter the cell phone',
        recordar: 'Remember',
        cancelar: 'Cancel',
        rememberFail: 'Usuario o celular incorrectos',
        olvidarContrasenaTitle: 'Have you forgotten your password?',
        olvidarContrasena: 'There is no problem, enter your registered email address and click on the recover password button to receive an email with the recovery link.',
        recuperarContrasena: 'Recover password',
        errorRecuperar: 'There was an error recovering your password or the email is not registered'
    },

    menuPrincipal: {
        organizacion: 'Organization',
        perfiles: 'Profiles',
        usuarios: 'Users',
        gruposDeInformacion: 'Information groups',
        menuPrincipal: 'Main menu',
        productos: 'Products & Services',
        embudo: 'Funnel',
        personas: 'People',
        tableroControl: 'Dashboard',
        reporteB: 'Report B',
        mensajes: 'Messaging',
        calendario: 'Calendar',
        historiaClinica: 'Clinic history',
        plantillas: 'Templates',
        canciones: 'Songs'
    },

    modulos: {
        configuracion: 'Configuration',
        diaDia: 'Day by Day',
        reportes: 'Reports'
    },

    recursos: {
        organizacion: 'Organization',
        perfiles: 'Profiles',
        usuarios: 'Users',
        gruposDeInformacion: 'Information groups',
        menuPrincipal: 'Main menu',
        productos: 'Products & Services',
        embudo: 'Funnels',
        crearEmbudos: 'Create funnel',
        personas: 'People',
        tableroControl: 'Dashboard',
        reporteB: 'Report B',
        mensajes: 'Messaging',
        calendario: 'Calendar',
        alertas: 'Alerts',
        tareas: 'Task',
        historiaClinica: 'Clinic history',
        mensajesAutomaticos: 'Automatic messages',
        plantillas: 'Templates',
        archivos: 'Document management',
        canciones: 'Songs'

    },

    usuarios: {
        nombres:'Name',
        nombre_placeholder: 'Enter username',
        apellidos:'Last name',
        apellidos_placeholder: "Enter the user's last name",
        usuario:'User',
        usuario_placeholder: 'Ingrese el correo del ususario',
        perfil:'Profile',
        grupoInformacion:'Information group',
        administrador: 'Administrator',
        si:'Yes',
        no:'No',
        clave: 'Password',
        clave_placeholder: 'Enter your password',
        claveDos:'Repeat password',
        claveDos_placeholder:'Repeat your password',

        mensajeAlerta: '¿Be sure you want to inactivate the user?',
        activarUsuario: '¿Be sure you want to Activate the user?',
        ordenUsuarioAsc:'By user (Ascending)',
        ordenUsuarioDes:'By user (Desc)',
        ordenPerfilAsc:'By profile (Ascending)',
        ordenPerfilDes:'By profile (Desc)',
    },

    usuariosDetalle: {
        usuario:'User configuration:',
        config_general: 'General configuration',
        usuarios: 'User',
        contrasena: 'Password',
        persona:'Person',
        perfiles:'Profiles',
        anadir:'Add',
        nombre:'Name',
        email: 'Email',
        resta_contrasena:'Restore password',
        contra_anterior:'Old Password',
        nueva_contrasena: 'New Password',
        confir_contrasena:'Confirm Password',
        cargarLogo: 'Click here to add an image',
        addPerfil:'Select the profile that you want to relate to this user',
        PerfilSlectExistente: 'The selected profile already exists in the profile list.',
        validacionPerfilVacio: 'You must select at least one profile',
        errorGuardarImagen: 'There was an error saving the user\'s image.',
        activo: 'Active',
        usuarioExiste: 'The user email you want to register already exists.'
    },

    perfiles: {
        perfil_filtro:'Filter profiles:',
        cant_user: 'Users quantity:',
        cant_recursos:'Amount of resources:',
        filtrar_perfiles:'Filter profiles',
        filtro_nombre_placeholder: 'Profile name',
        filtro_grupo_info: 'Information group',
        nombre:'Name',
        grupo:'Group',
        mensajeAlerta: '¿Be sure you want to inactivate the profile?. Because there are probably users who have that profile associated and will not have permissions.',
        activarPerfil: '¿Be sure you want to activate the profile?',
        ordenPerfilAsc:'By profile (Ascending)',
        ordenPerfilDes:'By profile (Desc)',
    },

    perfilesDetalle: {
        nombre:'Name',
        nombre_placeholder: 'Profile name',
        modulo:'Module',
        nombre_recurso:'Resource name',
        permisos: 'Permissions',
        consulta:'Query',
        modificacion:'Modify',
        eliminar:'Delete',
        agregar:'Add',
        volver:'Return'
    },

    personas: {
        nombres_fil:'Name',
        nombres_fil_placeholder: 'Enter the names of the person',
        apellidos_fil:'Last name',
        apellidos_fil_placeholder: 'Enter the last name of the person',

        nombres:'Name',
        apellidos:'Last name',
        telefonoMovil: 'Mobile',
        email: 'Email'

    },

    personasDetalle: {
        general: 'General',
        tipoDocumento: 'Type document',
        numeroIdentificacion: 'Identification Number',
        numeroIdentificacion_placeholder: 'Enter your identification number',
        nombres:'Names',
        nombres_placeholder: 'Enter the names of the person',
        apellidos:'Last name',
        apellidos_placeholder: 'Enter the last name of the person',
        telefonoMovil: 'Mobile',
        telefonoMovil_placeholder: 'Enter your cell phone number',
        telefonoFijo: 'Landline phone',
        telefonoFijo_placeholder: 'Enter your landline number',
        email: 'Email',
        email_placeholder: 'Enter the person\'s email address',
        direccion: 'Direction',
        direccion_placeholder: 'Enter the address of the person',
        pais: 'Country',
        departamento: 'Department',
        ciudad: 'City',
        tipoPersona: 'Type Person',
        genero: 'Gender',
        grupoDeInformacion: 'Information group',
        usuarioAsesor: 'Advisor user',
        personaExiste: "There is already a person registered with the document type and document number entered",
        movilExiste: "There is already a person registered with the same phone number.",
        asesorMensajeria: "Messaging Advisor",
        codArea: "Area code",
        camposObligatorios: "There are mandatory fields without filling out.",
        etiquetas: "Tags",
        sinAsesor: "Without adviser",
        otros: 'Others',
        observaciones: 'Observations',
        personasAsociadas: 'Associated people'
    },

    organizacion: {
        general: 'General',
        tipoDocumento: 'Type document',
        numeroIdentificacion: 'Identification Number',
        numeroIdentificacion_placeholder: 'Enter the identification number',
        nombres:'Name',
        nombres_placeholder: 'Enter the name of the organization',
        telefonoMovil: 'Mobile',
        telefonoMovil_placeholder: 'Enter the cell phone number',
        telefonoFijo: 'Landline phone',
        telefonoFijo_placeholder: 'Enter the landline number',
        email: 'Email',
        email_placeholder: 'Enter the organization email address',
        direccion: 'Direction',
        direccion_placeholder: 'Enter the address of the organization',
        pais: 'Country',
        departamento: 'Department',
        ciudad: 'City',
        coleccionesGlobales: 'Global Collections',
        redes: 'Networking',
        horaIni: 'Start Time',
        horaFin: 'End time',
        msgBienvenida: 'Welcome message',
        msgHorario: 'Business hours message'

    },

    producto: {
        nombre:'Name',
        codigoInterno: 'SKU',
        precio: 'Price'
    },

    productosDet: {
        general: 'General',
        nombre: 'Name',
        nombre_placeholder: 'Enter the product name',
        codigo: 'SKU',
        codigo_placeholder: 'Enter the internal code of the product or service',
        precio: 'Price',
        precio_placeholder: 'Enter the price of the product or service',
        grupoDeInformacion: 'Information group',
        descripcion: 'Description',
        descripcion_placeholder: 'Enter the description of the product or service'
    },

    embudo: {
        nuevo: 'New funnel',
        nuevaTarjeta: 'New card',
        nombre:'Name',
        nombre_placeholder: 'Enter the funnel name',
        etapas: 'Stages',
        nombreLead:'Name',
        nombreProducto: 'Name or Service',
        precioProducto: 'Price',
        grupoInformacion: 'Informtion group',
        cantidadProducto: 'Quantity',
        etapa: 'Stage',
        totalProducto: 'Total',
        evento: 'Event',
        fecha: 'Fecha',
        fechaCreacion: 'Creation date',
        url: 'Url',
        programarAlerta: 'Schedule alert',
        programarMsgAutomatico: 'Schedule message',
        observaciones: 'Observations',
        observaciones_placeholder: 'Enter event observations',
        acciones: 'Actions',
        eventos: 'Events',
        programado: 'Programmed:',
        nuevoEvento: 'New event',
        tipoEventoTitulo: 'Event type',
        estadoEventoTitulo: 'Event state',
        cantidadProductos: 'Quantity of products/services:',
        cantidadTarjetas:'Number of cards:',
        totalEtapa:'Total',
        tipoEvento : {
            llamada: 'Call', 
            tarea: 'Task', 
            wp: 'WhatsApp',
            wmail: 'Email',
            reunion: 'Meeting'
        },
        estadoEvento : {
            pendiente: 'Pending', 
            proceso: 'In process',
            finalizado: "Finalized", 
            cancelado: "Cancelled"
        },
        fechaDesde: 'Date From',
        fechaHasta: 'Date To',
        fechaDesdePersonas: 'Date From Persons',
        fechaHastaPersonas: 'Date To Persons',
        confirmacionMsgAutomatico: 'Are you sure you want to schedule an automated message?',
        usuarioParaAlerta: 'User for alert',
        personaParaMensaje: 'Person for scheduled message',
        historicoTarjeta: 'Card history',
        tableHistoricoTarjeta: {
            etapa: 'Stage',
            usuario: 'User',
            fechaIni: 'Start date',
            fechaFin: 'End date',
            tiempo: 'Time (Days)'
        }
    },

    grupoDeInformacion: {
        nombre_placeholder: 'Enter the name of the information group'
    },

    mensajeria: {
        contactos: "Contacts",
        chat: "Chat",
        enviar: "Send message",
        noEnviado: "Not send",
        modificarAsesor: "Do you want to modify the current advisor?",
        sinNumeroCelular: "The current person does not have a registered cell phone number.",
        errorEnviandoArchivo: "There was an error sending the file, please try again.",
        retomarPlantilla: "Recover template"
    },

    calendario: {
        observaciones: 'Observations'
    },

    tareas: {
        titulo: 'Task',
        etapaPendientes: 'Pending',
        etapaHaciendo: 'Doing',
        etapaFinalizado: 'Finished',
        escribeAqui: 'Write your task here.',
        editarTarea: 'Edit task'
    },

    historiaFiltros:{
        titulo: 'Medical record',
        seleccionTipoHistoria: 'Selet type of medical record',
        botonSeleccionHistGeneral: 'General',
        botonSeleccionHistGinecologo: 'Ginecologo',
        medicoTratante: 'Medico Tratante',
        rango_fechaDesde:'From',
        rango_fechaHasta: 'Until',
        fecha: 'Date',
        identificacion: 'ID',
        nombresPaciente: 'Names',
        apellidosPaciente: 'Surnames',
        ver: 'Look',
        imprimir: 'Print',
        nota: 'Note',
        filtrarHistoria: 'Filtrar Historia',
        ordenNombrePacienteAsc:'Por nombre paciente (Ascendente)',
        ordenNombrePacienteDes:'Por nombre paciente (Desc)',
        ordenFechaHistoriaAsc:'Por fecha historia (Ascendente)',
        ordenFechaHistoriaDes:'Por fecha historia (Desc)',
        descargarHistoria: 'History',
        descargarFormula: 'Formula'
    },

    historiaDetalle:{
        datosPaciente: 'Patient data',
        paciente: 'Paciente   ',
        nombre: 'Name',
        crear:'Crear',
        buscarPaciente: 'Nombres Apellidos Cedula',
        crearPaciente: 'Crear Paciente',
        historiaClinica: 'Historia Clinica',
        fecha: 'Fecha',
        medicoTratante: 'Medico',
        especialidad: 'Especialidad',
        historia: 'Historia',
        antecedentes: 'Antecedentes',
        personales: 'Personales',
        patologias: 'Patologias',
        traumaticas: 'Traumaticas',
        quirurgicas: 'Quirurgicas',
        farmologicas: 'Farmologicas',	
        alergias: 'Alergias',	
        transfuciones: 'Transfuciones',	
        inmunizaciones: 'Inmunizaciones',
        ginecobstetricos: 'Ginecobstetricos',
        menarquia: 'Menarquia',
        ciclos:	'Ciclos',
        planificacion: 'Planificación',	
        fum: 'FUM',
        familiares: 'Familiares',
        revisionPorSistemas: 'Revisión Por Sistemas',
        sistemasGenerales: 'Sistemas Generales',
        sistemaRespiratorio: 'Sistema Respiratorio',	
        sistemaCardiovascular: 'Sistema Cardiovascular',
        sistemaGastrointestinal: 'Sistema Gastrointestinal',
        sistemaGenitoUrinario: 'Sistema Genito Urinario',
        sistemaGenitoNeurologico: 'Sistema Genito Neurologico',
        examenFisico: 'Examen Físico',
        estadoGeneral: 'Estado General',
        signosVitales: 'Signos Vitales',
        presionarteriar: 'Presión Arteriar',
        pas: 'PAS',
        mmgh: 'mmgh',
        pad: 'PAD',
        pam: 'PAM',
        frecuenciaCardiaca: 'Frecuencia Cardiaca',
        latmin: 'Lat/Min',
        frecuenciaRespiratoria: 'Frecuencia Respiratoria',
        rpm: 'RPM',
        temperatura: 'Temperatura',
        grados: 'ºC',
        saturaciondeOxigeno: 'Saturación de Oxigeno',
        porcentaje: '%',
        peso: 'Peso',
        kg: 'KG',
        talla: 'Talla',
        cm: 'CM',
        imc: 'IMC',
        imcPlaceHolder: 'Kg/M2',
        cabezayCuello: 'Cabeza y Cuello',
        normal: 'Normal',
        anormal: 'Anormal',
        ojos: 'Ojos',
        oidos: 'Oidos',
        nariz: 'Nariz',
        boca: 'Boca',
        cuello: 'Cuello',
        normalAnormal: 'Normal/Anormal',
        analisisyPlan: 'Analisis y Plan',
        diagnostico: 'Diagnostico',
        conducta: 'Conducta',
        prescripcion: 'Prescripción de Medicamento',
        nombreDeMedicamento: 'Nombre de Medicamento',
        presentacion: 'Presentación',
        dosis: 'Dosis',
        via: 'Via',
        frecuencia: 'Frecuencia',
        duracion: 'Duración',
        observaciones: 'Observaciones',
        tipo: 'Tipo',
        unidad: 'Unidad',
        acompanante:'Acompañante',
        parentesco:'Parentesco',
        motivoConsultaEnfermedad: 'Motivo consulta y enfermedad actual',
        motivoConsulta: 'Motivo consulta',
        enfermedadActual: 'Enfermedad actual'
    },

    plantillasFil: {
        nombrePlantilla: 'Name template',
        nombrePlantilla_placeholder: 'Enter the name of the template here.'
    },

    plantillasDet: {
        general: 'General',
        nombre: 'Name template',
        nombrePlantilla_placeholder: 'Enter the name of the template here.',
        html: 'Html',
        limiteTamanio: 'The file size exceeds the limit.',
        variables: 'Variables'
    },

    gestionDocumental: {
        nombreArchivo: "File name",
        archivoNuevo: 'New file'
        
    },

    canciones: {
        nombre: 'Song name',
        tonalidad: 'Tonality',
        listasAsociadas: 'Associated lists',
        modo: 'Mode',
        transportar: 'Transport',
        genero: 'Genre',
        adminListas: 'Manage lists',
        nombreLista: 'List name',
        orden: 'Order'
    }

}
